export const translations = {
    en: {
        EntitySelectView: {
            back: "Back",
            results_found: "results found.",
            stop_scan: "Stop scan",
            modified_settings: "Modified settings",
            selected: "Selected",
            entity_types: "Entity types",
            datasets: "Datasets",
            hover_on_a: "Select a highlight to view matches",
            select_matching_entity: "Select a matching entity below.",
            entities_matching: "entities found matching",
            all_types: "All types",
            connected_entities: "Connected entities",
            view_documentation: "View documentation",
        },
        EntityInfoView: {
            back: "Back",
            back_to_categories: "Back to categories",
        },
        CategorySelection: {
            filter_by_category: "Filter by category",
        },
        EntityDetailsDropdown: {
            summary: "Summary",
            dates: "Dates",
            birth: "Birth",
            death: "Death",
            created: "Created",
            dataset: "Dataset",
            uri: "URI",
            view_more: "View more details",
        },
        EntityList: {
            sort_by: "Sort By",
            alphabetical: "Alphabetical",
        },
        EntityResourceCard: {
            connection: "Connection",
            predicate: "Predicate",
        },
        EntityTimeline: {
            sort_by: "Sort By",
            newest_to_oldest: "Newest to oldest",
            oldest_to_newest: "Oldest to newest",
            undated: "Undated",
        },
        EntityViewSelector: {
            list_view: "List view",
            timeline_view: "Timeline view",
        },
        ResearchSpaceButton: {
            view_resource_on: "View resource on ResearchSpace",
        },
        SidePanelHeader: {
            context_explorer: "Context Explorer",
        },
        ThirdLevelTimeline: {
            viewing: "Viewing",
        },
        ViewMoreDetailsView: {
            back: "Back",
            properties: "Properties",
            type: "Type",
            uri: "URI",
            sources: "Sources",
        },
    },
    fr: {
        EntitySelectView: {
            back: "Retour",
            results_found: "résultats trouvés.",
            stop_scan: "Arrêter le balayage",
            modified_settings: "Paramètres modifiés",
            selected: "Sélectionné",
            entity_types: "Types d'entités",
            datasets: "Jeux de données",
            hover_on_a:
                "Sélectionnez une partie mise en évidence pour voir les correspondances.",
            select_matching_entity: "Sélectionnez une entité correspondante ci-dessous.",
            entities_matching: "entités trouvées correspondant",
            all_types: "Tous types",
            connected_entities: "Entités connectées",
            view_documentation: "Voir documentation",
        },
        EntityInfoView: {
            back: "Retour",
            back_to_categories: "Retour aux catégories",
        },
        CategorySelection: {
            filter_by_category: "Filtrer par catégorie",
        },
        EntityDetailsDropdown: {
            summary: "Résumé",
            dates: "Dates",
            birth: "Naissance",
            death: "Décès",
            created: "Créé",
            dataset: "Jeu de données",
            uri: "URI",
            view_more: "Voir plus de détails",
        },
        EntityList: {
            sort_by: "Trier par",
            alphabetical: "Alphabétique",
        },
        EntityResourceCard: {
            connection: "Lien",
            predicate: "Prédicat",
        },
        EntityTimeline: {
            sort_by: "Trier par",
            newest_to_oldest: "Du plus récent au plus ancien",
            oldest_to_newest: "Du plus ancien au plus récent",
            undated: "Non daté",
        },
        EntityViewSelector: {
            list_view: "Vue liste",
            timeline_view: "Vue chronologique",
        },
        ResearchSpaceButton: {
            view_resource_on: "Visualiser la ressource dans ResearchSpace",
        },
        SidePanelHeader: {
            context_explorer: "Explorateur de Contexte",
        },
        ThirdLevelTimeline: {
            viewing: "Visualiser",
        },
        ViewMoreDetailsView: {
            back: "Retour",
            properties: "Propriétés",
            type: "Type",
            uri: "URI",
            sources: "Sources",
        },
    },
};
